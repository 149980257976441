import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import dataService from './dataService.js';

import naive from 'naive-ui'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

const app_initialize = async () => {

  const { getAuthUser, getOptions, getRescueActive } = dataService();

  // ログイン中のユーザ情報を読み込む
  await getAuthUser();
  //await getOptions();
  //await getRescueActive();

  const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(naive)
    .use(Antd);

  router.isReady().then(() => {
    app.mount('#app');
  });

}

app_initialize();

console.log("[Import] dataService.js")

import { ref } from "vue";
import { customAlphabet } from 'nanoid'
import { format } from 'date-fns';
import axios from 'axios';

// ここは1リクエストで初回に1度だけ呼ばれる
const productList = ref();
const displayError = ref();
const session = ref();
const user = ref(null);

const rescue = ref(
    {
        arrival_time: null,
        created_at: null,
        deleted_at: null,
        departure_time: null,
        evaluation_comment: "",
        evaluation_rate: 0,
        message: "",
        owner_id: 0,
        reception_number: "",
        reception_time: null,
        report_memo: "",
        rescue_title: "",
        situation: null,
        place: null,
        updated_at: null,
        vendor_id: 0,
        work_type: null,
        working_memo: "",
        working_status: null,
        rescue_close_date: null,

        field_photos: ["", "", ""],
        report_photos: ["", "", ""],

        vendor: null,
        owner: null,
    }
);

const progress_shared = ref(
    {
        form: {
            progress_status: "",
            working_memo: "",
            arrival_plan_time: null,

            working_finish_time: null,
            report_memo: "",
            report_photos: ["", "", ""],
        },
        rescue: null,
    }
);


const options = ref(
    {
        TargetPlace: [],
        WorkType: [],
        Situation: [],
        WorkingStatus: []
    }
);


const dataService = () => {

    console.log("Called dataService");

    const initialize = () => {
        axios.defaults.baseURL = process.env.VUE_APP_LARAVEL_API_ENDPOINTSUPABASE_URL; // APIのエンドポイントURLを設定
        axios.defaults.withCredentials = true; // CORS対策
    };

    const forgotPassword = async (email) => {
        let { user, error } = await SUPABASE_CLIENT.auth.api.resetPasswordForEmail(
            email
        );

        console.log(error && error.message);
        return { user, error };
    };

    const changePassword = async (password) => {
        const { user, error } = await SUPABASE_CLIENT.auth.update({
            password,
            data: {
                password_changed: new Date(),
            },
        });

        console.log(error && error.message);
        return { user, error };
    };


    const loginOwner = async (email, password) => {

        let error = null;
        try {
            await axios.get('/sanctum/csrf-cookie'); // CSRF用のセッションを初期化
            const res = await axios.post('/login', {
                email: email,
                password: password
            })
            user.value = res.data.data;
        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }

        return { error };
    };

    const loginVendor = async (email, password) => {

        let error = null;
        try {
            await axios.get('/sanctum/csrf-cookie'); // CSRF用のセッションを初期化
            const res = await axios.post('/vendor/login', {
                email: email,
                password: password
            })
            user.value = res.data;
        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }

        return { error };
    };


    // ログイン中のユーザ情報を取得する
    const getAuthUser = async () => {
        let error = null;
        try {
            const res = await axios.get('/auth-user');
            if (res.data) {
                user.value = res.data;
            }
        } catch (_error) {
            console.log("ErrorCode:", _error.code, "ErrorMessage:", _error.message);
            error = _error;
        }
        return { error };
    };

    const loadOptions = async () => {
        let error = null;
        try {
            const res = await axios.get('/api/options');
            options.value = res.data;
        } catch (_error) {
            console.log("ErrorCode:", _error.code, "ErrorMessage:", _error.message);
            error = _error;
        }
        return { error };
    };




    const logout = async () => {
        let error = null;
        let userType = '';
        try {
            const res = await axios.get('/logout');
            userType = res.data;
            user.value = null;
        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }

        return { error, userType };
    };

    const upload = async (file) => {

        let error = null;
        let fileUrl = null;

        try {
            const params = new FormData();
            params.append('file', file);
            const res = await axios.post('/api/files', params, { headers: { 'content-type': 'multipart/form-data' } });
            fileUrl = res.data.url;
        } catch (_error) {
            error = _error;
            console.log("error :" + error);
        }

        return { fileUrl, error }
    };



    const createRescue = async (state) => {

        let error = null;
        let data = null;
        try {
            const res = await axios.post('/api/rescues', {
                rescue: state
            })

            data = res.data;

        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }
        return { error, data };
    };




    const getRescueActive = async (state) => {
        let error = null;
        let data = null;
        try {
            const res = await axios.get('/api/rescues/active');
            if (res.data) {
                data = res.data.data;
                console.log('getRescueActive:', data);
            }
        } catch (_error) {
            console.log("ErrorCode:", _error.code, "ErrorMessage:", _error.message);
            error = _error;
        }
        return { error, data };
    };

    const getRescue = async (id) => {
        let error = null;
        let data = null;
        try {
            const res = await axios.get(`/api/rescues/${id}`);
            if (res.data) {
                data = res.data.data;
                console.log('getRescue:', data);
            }
        } catch (_error) {
            console.log("ErrorCode:", _error.code, "ErrorMessage:", _error.message);
            error = _error;
        }
        return { error, data };
    };


    const getRescues = async (params) => {
        let error = null;
        let data = null;
        try {
            const res = await axios.get('/api/vendor/rescues', { params: params });
            if (res.data) {
                data = res.data.data;
                console.log('getVendorRescues:', data);
            }
        } catch (_error) {
            console.log("ErrorCode:", _error.code, "ErrorMessage:", _error.message);
            error = _error;
        }
        return { error, data };
    };

    const departureRescue = async (params) => {

        let error = null;
        let data = null;
        try {
            const res = await axios.put('/api/vendor/rescues/departure', params);
            data = res.data;
        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            error = e.response;
        }
        return { error, data };
    };


    const updateRescueStatus = async (params, id) => {

        let error = null;
        let data = null;
        try {
            const res = await axios.put(`/api/vendor/rescues/status/${id}`, params)

            if (res.data) {
                data = res.data.data;
                console.log('updateRescueStatus:', data);
            }

        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }
        return { error, data };
    };

    const sendReport = async (params, id) => {

        let error = null;
        let data = null;
        try {
            const res = await axios.put(`/api/vendor/rescues/report/${id}`, params);

            if (res.data) {
                data = res.data.data;
                console.log('sendReport:', data);
            }

        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }
        return { error, data };
    };


    const evaluateRescue = async (params, id) => {

        let error = null;
        let data = null;
        try {
            const res = await axios.put(`/api/rescues/evaluate/${id}`, params);
            if (res.data) {
                data = res.data.data;
                console.log('evaluateRescue:', data);
            }
        } catch (e) {
            console.log("Error:", e.response.status);
            console.log("Error:", e.response.data.message);
            console.log("Error:", e.response.data.errors);
            error = e.response;
        }
        return { error, data };
    };

    const formatDT = (datetimeText, formatPattern, nullText = '未定') => {
        if (datetimeText === null || datetimeText === '') {
            return nullText;
        } else if (datetimeText === void 0) {
            return 'undefined';
        }
        return format(new Date(datetimeText.replace(/-/g, "/")), formatPattern);
    };


    return {
        hasUser: () => {
            return user.value !== null;
        },

        user,
        progress_shared,

        options,
        rescue,
        createRescue,
        getRescueActive,
        evaluateRescue,
        getRescues,
        getRescue,
        departureRescue,
        updateRescueStatus,
        sendReport,
        loginOwner,
        loginVendor,

        formatDT,
        initialize,
        getAuthUser,
        logout,
        forgotPassword,
        changePassword,
        loadOptions,
        upload,
    };
};


dataService().initialize();

export default dataService;

<script setup>
import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonImg, IonButton, IonFooter, toastController } from "@ionic/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import dataService from "../../dataService.js";
import logoHome from "../../../public/assets/login-title-home.png";
const router = useRouter();

const {
    loginVendor,
    forgotPassword
} = dataService();

let isLoginButtonDisabled = ref(true);

const creds = ref({
    email: "",
    password: ""
});

const doLogin = async () => {
    isLoginButtonDisabled.value = false;
    try {
        const { email, password } = creds.value;
        const { error } = await loginVendor(email, password);
        if (error) throw error;
        router.replace("/vendor/home");
    } catch (e) {
        console.log(e);
        const toast = await toastController
            .create({
                message: "ログインに失敗しました。",
                duration: 1000
            })
        toast.present();
        toast.onWillDismiss();
        isLoginButtonDisabled.value = true;
    }

};


const doForgotPassword = async () => {
    try {
        const { email } = creds.value;
        const { user, error } = await forgotPassword(email);
        if (user) alert("Check Email to Reset Password");
        if (error) throw error;
    } catch (e) {
        alert(e.message);
    }
};

</script>

<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-padding">
            <div class="ion-text-center">
                <div class="ion-padding">
                    <h2>コールセンター代替サービス</h2>
                    <h3>かけつけ業社用サイト</h3>
                    <ion-img
                        :src="logoHome"
                        style="width:60%; display: block; margin: 0 auto; padding-top: 15px;"
                    ></ion-img>
                </div>
            </div>

            <n-space vertical>
                <n-input
                    size="large"
                    round
                    v-model:value="creds.email"
                    type="text"
                    placeholder="ログインID"
                />
                <n-input
                    size="large"
                    round
                    v-model:value="creds.password"
                    type="password"
                    show-password-on="mousedown"
                    placeholder="パスワード"
                />
            </n-space>

            <div class="ion-padding">
                <ion-button @click="doLogin" expand="block" :disabled="!isLoginButtonDisabled">ログイン</ion-button>
            </div>
        </ion-content>
        <!--
        <ion-footer class="ion-text-center">
            <ion-button @click="doForgotPassword" fill="clear">パスワードを忘れた方はこちら</ion-button>
        </ion-footer>
        -->
    </ion-page>
</template>



<style scoped>
</style>
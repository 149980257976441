console.log("[Import] DS > in-progress")

import { ref } from "vue";

const shared_form = ref(
    {
        progress_status: "",
        working_memo: "",
        arrival_plan_time: null,

        working_finish_time: null,
        report_memo: "",
        report_photos: ["", "", ""],

    }
);

const shared_rescue = ref(null);

const dataService = () => {
    const initialize = () => {
    };

    const test = () => {
        console.log(shared_rescue.value);
    };


    return {
        initialize,
        shared_form,
        shared_rescue,
        test,
    };
};

dataService().initialize();

export default dataService;

// Composition Function
import { loadingController } from '@ionic/vue';
import { delay } from './common.js';

let ion_loading = null;

export const loading = {

  show: async function (duration = 0) {
    if (ion_loading) {
      this.hide();
    }
    ion_loading = await loadingController
      .create({
        spinner: "crescent",
        message: 'お待ちください...',
      });
    await ion_loading.present();

    if (duration > 0) {
      await delay(duration);
      this.hide();
    }
  },

  hide: function () {
    if (ion_loading) {
      ion_loading.dismiss();
      ion_loading = null;
    }
  },
};


<script setup>

import { IonCol, loadingController, IonSelect, IonSelectOption, useIonRouter, IonRouterOutlet, IonLabel, IonDatetime, IonModal, IonMenuButton, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { ref } from "vue";
import dataService from '../../dataService.js';
import { useLoading } from '../../components/molecule/useLoading.js'
import { useRouter } from "vue-router";
import { loading } from '../../components/molecule/loading.js'
import { VehicleCar24Filled, Check20Regular, HomePerson20Regular } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { format, parseISO } from 'date-fns';
import FileUpload from "../../components/FileUpload.vue"


const time = ref(null);
const count = ref(0);
const router = useRouter();

const isConfirmModalOpen = ref(false);


const customAlertOptions = ref({
  header: '状況の更新',
  translucent: true
});

if (!route.params.s) {
  route.params.s = 1;
}
const s = ref(route.params.s);

const status = ref("移動中");

const formatDate = (value) => {
  return format(parseISO(value), 'HH:mm');
};

const value = ref(null);
const {
  displayError,
  productList,
  removeProduct,
  user,
  logout
} = dataService();


onIonViewWillEnter(() => {
  console.log('onIonViewWillEnter!');
});

onIonViewDidEnter(() => {
  console.log('onIonViewDidEnter!');
});

const close = () => {
  isConfirmModalOpen.value = false;
  router.navigate("/supplier/home", 'root', 'pop');
};

const kkk = ref(null)

const showModal = ref(false);

</script>

<template>
  <ion-page>
    <IconConfigProvider color="green" size="34">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button defaultHref="/supplier/home"></ion-back-button>
          </ion-buttons>
          <ion-title>レスキュー作業完了報告</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :fullscreen="true">
        <div class="ion-padding">
          <ion-item lines="full" id="open-datetime">
            <ion-label>完了時刻</ion-label>
            {{ time ? formatDate(time) : '17:30' }}
            <ion-modal class="datetime-modal" trigger="open-datetime">
              <ion-content>
                <ion-datetime
                  v-model="time"
                  cancel-text="キャンセル"
                  doneText="決定"
                  presentation="time"
                  :show-default-buttons="true"
                ></ion-datetime>
              </ion-content>
            </ion-modal>
          </ion-item>

          <ion-item lines="full">
            <ion-label position="stacked" mode="ios">報告内容</ion-label>
            <ion-textarea rows="4" cols="20" placeholder></ion-textarea>
          </ion-item>

          <file-upload v-model:url="url" v-model:label="label" title="現場写真 1" />
          <file-upload v-model:url="url" v-model:label="label" title="現場写真 2" />
          <file-upload v-model:url="url" v-model:label="label" title="現場写真 3" />

          <div class="ion-padding">
            <ion-button @click="isConfirmModalOpen = true" expand="block">
              <Icon color="white" size="28">
                <Check20Regular />
              </Icon>&nbsp;確認
            </ion-button>
          </div>
        </div>
      </ion-content>
    </IconConfigProvider>
  </ion-page>
</template>



<style scoped>
.datetime-modal ion-datetime,
.datetime-popover ion-datetime {
  --background: var(--ion-color-step-50, #fff);
}
.datetime-popover {
  --width: 350px;
}
.datetime-modal {
  --border-radius: 8px;
  --width: 325px;
  --height: 254px;
}
</style>
<script setup>
import { IonIcon, IonApp, IonRouterOutlet, IonLabel, IonItem, IonList, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { logOutOutline, personCircleOutline } from "ionicons/icons";
import { ref } from "vue";
import { useRouter } from "vue-router";
import dataService from '../dataService.js';
const router = useRouter();
const menuDisabled = ref(false);

const {
  logout
} = dataService();


const doLogout = async () => {

  const alert = await alertController
    .create({
      header: 'ログアウト',
      message: 'ログアウトしてもよろしいですか？',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'OK',
          role: 'ok',
          handler: () => {
          },
        },
      ],
    });

  await alert.present();
  const { role } = await alert.onWillDismiss();
  if (role === "ok") {
    menuController.close('menu');
    const { error, userType } = await logout();
    console.log(userType);

    if (userType === 'owner') {
      router.replace("/login");
    } else if (userType === 'vendor') {
      router.replace("/vendor/login");
    }


  }

};

</script>

<template>
  <ion-menu
    :disabled="menuDisabled"
    swipe-gesture="false"
    side="end"
    menu-id="menu"
    content-id="ion-router-outlet-id"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>メニュー</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <!--<ion-item button>
          <ion-icon :icon="personCircleOutline" size="large" slot="start"></ion-icon>
          <ion-label>マイページ</ion-label>
        </ion-item>
        -->
        <ion-item button @click="doLogout()">
          <ion-icon :icon="logOutOutline" size="large" slot="start"></ion-icon>
          <ion-label>ログアウト</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<style scoped>
</style>
<script setup>
import { IonBackButton, IonButtons, IonGrid, IonMenuButton, IonCol, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { pin, walk, warning, wifi, wine } from 'ionicons/icons';
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import dataService from '../dataService.js';
const router = useRouter();
const { options, rescue, loadOptions, } = dataService();

// Init state.
rescue.value.work_type = '';

const setWorkType = (work_type) => {
  rescue.value.work_type = options.value.WorkTypes.find(item => item.value === work_type);
};

onIonViewWillEnter(async () => {
  await load();
});

const load = async () => {
  const { error } = await loadOptions();
  //rescue.value = data;
};

onIonViewWillEnter(() => {
  console.log("onIonViewWillEnter");
});
onMounted(() => {
  console.log("onMounted");
});




</script>

<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <ion-title>レスキュー要請</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <h3 style="text-align: center;" class="ion-padding-top">トラブルの種別選択</h3>
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/water-leak.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('water_leak'); router.push('/input-trouble?t=water_leak');"
                  expand="block"
                  color
                >水漏れ</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/key.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('key_open'); router.push('/input-trouble')"
                  expand="block"
                  color
                >鍵開け</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/window-glass.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('window_glass'); router.push('/input-trouble')"
                  expand="block"
                  color
                >窓ガラス</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/air-conditioner.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('air_conditioner'); router.push('/input-trouble')"
                  expand="block"
                  color
                >エアコン</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/water-heater.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('water_heater'); router.push('/input-trouble')"
                  expand="block"
                  color
                >給湯器</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
          <ion-col>
            <ion-card>
              <img src="../../public/assets/image/water-leak.jpg" style />
              <ion-card-content>
                <ion-button
                  @click="setWorkType('other'); router.push('/input-other-trouble')"
                  expand="block"
                  color="light"
                >その他</ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-card {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
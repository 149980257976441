<script setup>

import { onIonViewWillLeave, IonRefresherContent, IonRefresher, toastController, loadingController, useIonRouter, IonRouterOutlet, IonLabel, IonMenuButton, IonTextarea, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { ref, computed } from "vue";
import dataService from '../dataService.js';
import { useLoading } from '../components/molecule/useLoading.js'
import { useRoute } from "vue-router";
import { loading } from '../components/molecule/loading.js'
import { DismissCircle20Filled, Clock20Regular } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { delay } from '../components/molecule/common.js';

const router = useIonRouter();

const {
  getRescueActive,
  evaluateRescue,
  logout,
  formatDT
} = dataService();

const rescue = ref(null);
const evaluation_rate = ref(0);
const evaluation_comment = ref("");
let intervalId = null;

// 作業評価を送信ボタンをクリック
const sendEvaluation = async () => {

  await loading.show();
  const { error, data } = await evaluateRescue({
    evaluation_rate: evaluation_rate.value,
    evaluation_comment: evaluation_comment.value,
  }, rescue.value.id);

  if (error) {
    let error_message = [];
    Object.entries(error.data.errors).forEach(([key, item]) => {
      item.forEach((message) => error_message.push(message));
    });
    const toast = await toastController
      .create({
        message: error_message.join('<br>'),
        duration: 1000
      });
    toast.present();
    loading.hide();

  } else {

    clearInterval(intervalId);

    loading.hide();
    /*
        const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'レスキュー終了',
            //backdropDismiss: false,
            subHeader: '',
            //        message: '<div class="message">ありがとうございました。</div>',
            message: '<div class="message">ありがとうございました。</div>',
    
            buttons: ['閉じる'],
          });
    
        await alert.present();  // Alertを表示する   
        await alert.onDidDismiss(); // ボタンが押されるまで待つ
    */

    await logout();
    router.replace("/login");

    //    router.navigate("/dashboard", 'root', 'pop'); // ダッシュボードへ一気に戻る


  }
}

onIonViewWillEnter(async () => {
  await fetch();
  intervalId = setInterval(() => fetch(), 5000);
});

const fetch = async () => {
  const { error, data } = await getRescueActive();
  rescue.value = data;
};

const doRefresh = async (ev) => {
  await delay(0.3);
  await fetch();
  ev.target.complete();
};

const current_step = computed(() => {
  const ws = rescue.value.working_status.value;
  if (ws === 'finish') return 5;
  if (ws === 'arrival') return 4;
  if (ws === 'departure') return 3;
  return 1; // reception
});


onIonViewWillLeave(() => {
  clearInterval(intervalId);
  console.log("Subscribed setInterval");
});


</script>

<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>ダッシュボード</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="rescue !== null">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <div class="ion-padding">
        <template v-if="rescue.working_status.value !== 'finish'">
          <n-card
            class="ion-text-center ion-margin-top"
            size="small"
            style="border-color: red; border-width: 3px;"
            embedded
          >
            <span style="color:red; font-size: 1.5em;">
              <strong>レスキュー要請中です</strong>
            </span>
          </n-card>
        </template>
        <template v-else>
          <n-card
            class="ion-text-center"
            size="small"
            embedded
            style="border-color: gray; border-width: 3px;"
          >
            <span style="color:gray; font-size: 1.5em;">
              <strong>報告書のご確認と評価</strong>
            </span>
          </n-card>
        </template>

        <template v-if="rescue.working_status.value !== 'finish'">
          <n-divider dashed style="margin-top: 12px; margin-bottom: 12px;">
            <!--
          <Icon size="32" style="vertical-align: middle;">
            <Clock20Regular />
          </Icon>
            -->
            <div
              style="vertical-align: middle; display: inline-block; font-weight: bold; font-size: 1.4em;"
            >状況</div>
          </n-divider>

          <div id="n-steps-area" class>
            <n-steps vertical :current="current_step" size="medium">
              <n-step :title="'要請受付 ' + formatDT(rescue.reception_time, 'H:mm')">
                要請内容：{{ rescue.work_type.label }}
                <br />
              </n-step>

              <n-step title="業社確定">
                <span
                  v-if="rescue.departure_plan_time !== null"
                >業者出動予定時刻：{{ formatDT(rescue.departure_plan_time, 'H:mm') }}</span>
              </n-step>

              <n-step title="出動">
                <span
                  v-if="rescue.arrival_plan_time !== null"
                >到着予定時間：{{ formatDT(rescue.arrival_plan_time, 'H:mm') }}</span>
              </n-step>
              <n-step title="到着">
                <span v-if="current_step === 4">現在対応中</span>
              </n-step>
              <n-step :title="'完了 ' + formatDT(rescue.working_finish_time, 'H:mm', '')"></n-step>
            </n-steps>
          </div>
        </template>

        <template v-if="rescue.working_status.value === 'finish'">
          <div style="padding-top:8px;">
            <n-divider dashed style="margin-top: 12px; margin-bottom: 12px;">
              <h4>★ 作業報告書 ★</h4>
            </n-divider>
          </div>
          <n-space vertical>
            <n-card size="small" embedded :bordered="true">
              <h4>ご依頼内容</h4>
              レスキュー内容：{{ rescue.work_type.label }}
              <br />
              要請日時：{{ formatDT(rescue.reception_time, 'M/dd H:mm') }}
              <br />
              住所：{{ rescue.rescue_title }}
              <br />
              場所：{{ rescue.place.label }}
              <br />
              状況：{{ rescue.situation.label }}
              <br />
              連絡事項：{{ rescue.message }}
              <n-image-group>
                <n-space style="padding-top: 10px;">
                  <a-image
                    v-for="(photo, index) in rescue.field_photos"
                    :key="index"
                    :width="100"
                    :src="photo"
                  />
                </n-space>
              </n-image-group>
              <h4 style="padding-top: 18px;">対応内容</h4>
              対応結果：{{ rescue.working_result.label }}
              <br />
              完了日時：{{ formatDT(rescue.working_finish_time, 'M/dd H:mm') }}
              <br />
              状況報告：{{ rescue.report_memo }}
              <n-image-group>
                <n-space style="padding-top: 10px;">
                  <a-image
                    v-for="(photo, index) in rescue.report_photos"
                    :key="index"
                    :width="100"
                    :src="photo"
                  />
                </n-space>
              </n-image-group>
            </n-card>
          </n-space>

          <div style="padding-top:8px;">
            <n-divider dashed style="margin-top: 12px; margin-bottom: 12px;">
              <h4>★ 評価をお願いします ★</h4>
            </n-divider>
          </div>
          <n-card size="small">
            今回の当社の対応はいかがでしたでしょうか？
            <br />5段階の評価をいただけたら幸いです。
            <br />また、何か気になる点がございましたらコメント欄にご記入ください。
          </n-card>

          <n-form ref="formRef" class="ion-padding-top">
            <n-form-item label="評価">
              <n-rate :size="40" v-model:value="evaluation_rate" />
            </n-form-item>
            <n-form-item path="age" label="コメント">
              <n-input type="textarea" v-model:value="evaluation_comment" placeholder />
            </n-form-item>
          </n-form>

          <ion-button @click="sendEvaluation()" expand="block">報告書を確認しました</ion-button>
        </template>
      </div>
    </ion-content>
  </ion-page>
</template>


<style scoped>
.n-step {
  text-align: left;
}

#n-steps-area {
  padding-left: 1em;
}
</style>
<script setup>

import { onIonViewWillLeave, IonRefresherContent, IonRefresher, IonChip, IonListHeader, IonItemSliding, loadingController, useIonRouter, IonRouterOutlet, IonLabel, IonMenuButton, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { ref } from "vue";
import dataService from '../../dataService.js';
import { useLoading } from '../../components/molecule/useLoading.js'
import { useRouter } from "vue-router";
import { loading } from '../../components/molecule/loading.js'
import { home, walk, warning, wifi, wine } from 'ionicons/icons';
import { HomePerson20Regular, BuildingRetail20Regular, Send16Filled } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { delay } from '../../components/molecule/common.js';

const router = useRouter();

const {
  getRescues,
  formatDT
} = dataService();

const rescuesInRequest = ref([]);
const rescuesInWork = ref([]);
const rescuesInReport = ref([]);
let intervalId = null;

onIonViewWillEnter(async () => {
  await fetch();
  intervalId = setInterval(() => fetch(), 5000);
});


const fetch = async () => {
  const [response1, response2, response3] = await Promise.all([
    getRescues({ search_type: 'request' }),
    getRescues({ search_type: 'work' }),
    getRescues({ search_type: 'report' })
  ]);

  rescuesInRequest.value = response1.data;
  rescuesInWork.value = response2.data;
  rescuesInReport.value = response3.data;

};

const doRefresh = async (ev) => {
  await delay(0.3);
  await fetch();
  ev.target.complete();

};

onIonViewWillLeave(() => {
  clearInterval(intervalId);
  console.log("Subscribed setInterval");
});


</script>

<template>
  <ion-page>
    <IconConfigProvider color="green" size="48">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>鈴木水道工事株式会社</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :fullscreen="true">
        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>

        <ion-list>
          <ion-list-header>
            <ion-label color="danger">レスキュー要請中</ion-label>
          </ion-list-header>

          <template v-if="rescuesInRequest.length === 0">
            <div class="ion-padding-start">ありません</div>
          </template>

          <ion-item
            v-for="(rescue, index) in rescuesInRequest"
            :key="index"
            button
            @click="router.push(`/vendor/detail/${rescue.id}`)"
            detail
          >
            <ion-label color>
              {{ formatDT(rescue.reception_time, 'M/dd H:mm') }}&nbsp;
              {{ rescue.rescue_title }}&nbsp;
              {{ rescue.work_type.label }}
            </ion-label>
          </ion-item>

          <ion-list-header>
            <ion-label color="primary">レスキュー対応中</ion-label>
          </ion-list-header>

          <template v-if="rescuesInWork.length === 0">
            <div class="ion-padding-start">ありません</div>
          </template>

          <ion-item
            v-for="(rescue, index) in rescuesInWork"
            :key="index"
            button
            @click="router.push(`/vendor/in-progress/${rescue.id}`)"
            detail
          >
            <ion-label>
              {{ formatDT(rescue.reception_time, 'M/dd H:mm') }}&nbsp;
              {{ rescue.rescue_title }}&nbsp;
              {{ rescue.work_type.label }}
            </ion-label>
          </ion-item>

          <ion-list-header>
            <ion-label color="success">1週間以内に終了した作業</ion-label>
          </ion-list-header>

          <template v-if="rescuesInReport.length === 0">
            <div class="ion-padding-start">ありません</div>
          </template>


          <ion-item
            v-for="(rescue, index) in rescuesInReport"
            :key="index"
          >
            <ion-label>
              <ion-chip v-if="rescue.working_result.value === 'completed'" color="success">
                <ion-label>完了</ion-label>
              </ion-chip>
              <ion-chip v-if="rescue.working_result.value === 'incomplete'" color="warning">
                <ion-label>未完了終了</ion-label>
              </ion-chip>
              <ion-chip v-if="rescue.working_result.value === 'cancel'" color="danger">
                <ion-label>キャンセル</ion-label>
              </ion-chip>
              {{ formatDT(rescue.reception_time, 'M/dd') }}&nbsp;
              {{ rescue.rescue_title }}&nbsp;
              {{ rescue.work_type.label }}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </IconConfigProvider>
  </ion-page>
</template>



<style scoped>
.n-step {
  text-align: left;
}

#n-steps-area {
  padding-left: 1em;
}
</style>
<script setup>
import { IonContent, IonPage, IonButton, IonHeader, IonToolbar, IonTitle } from "@ionic/vue";
import { ref } from "vue";
import { useIonRouter } from '@ionic/vue';
import dataService from "../dataService";
const router = useIonRouter();
const { login, forgotPassword } = dataService();
import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ', 20)


const db = getFirestore();
connectFirestoreEmulator(db, 'localhost', 8080);

const test = async () => {

    // Add a new document in collection "cities"
    await addDoc(collection(db, "cities"), {
        name: "Los Angeles",
        state: "CA",
        country: "USA",
        map_test : { a: { c : 333 }, b : 2 },
        asddsad :[ "dasdsa", "dsads222" ]
    });

};


</script>


<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>e1</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" class="ion-padding">
            <ion-button @click="test()">test</ion-button>

            <ion-button @click="router.push('/e2')">To e2</ion-button>
        </ion-content>
    </ion-page>
</template>



<style scoped>
</style>
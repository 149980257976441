<script setup>
import { IonContent, IonPage,IonButton, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/vue";
import { ref } from "vue";
import { useIonRouter } from '@ionic/vue';
import dataService from "../dataService";
const router = useIonRouter();
const { login, forgotPassword } = dataService();




</script>


<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref="/e"></ion-back-button>
                </ion-buttons>
                <ion-title>e2</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" class="ion-padding">
            <ion-button>test</ion-button>
        </ion-content>
    </ion-page>
</template>



<style scoped>
</style>
<script setup>

import { onIonViewDidLeave, toastController, useIonRouter, IonText, IonModal, IonTextarea, IonThumbnail, IonSelect, IonSelectOption, IonBackButton, IonButtons, IonGrid, IonMenuButton, IonCol, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel, IonRouterOutlet } from '@ionic/vue';
import { ref } from "vue";
import dataService from '../dataService.js';
import { logOutOutline, camera, documentTextOutline } from "ionicons/icons";
import FileUpload from "../components/FileUpload.vue"
import { loading } from '../components/molecule/loading.js'
import { delay } from '../components/molecule/common.js';

import { useRouter } from "vue-router";

const router = useRouter();

const isConfirmModalOpen = ref(false);
const {
  rescue,
  user,
  options,
  createRescue,
  getRescueActive,
} = dataService();

const img = ref([null, null, null]);



// Init state.
rescue.value.situation = null;
rescue.value.place = null;
rescue.value.field_photos = ["", "", ""];
rescue.value.message = "";





onIonViewWillEnter(() => {
});

onIonViewDidLeave(() => {
  console.log("onIonViewDidLeave");
});



// 依頼を申し込むボタンをクリック
const applicationRequest = async () => {

  await loading.show();
  const { error, data } = await createRescue(rescue.value);

  if (error) {

    let error_message = [];
    Object.entries(error.data.errors).forEach(([key, item]) => {
      item.forEach((message) => error_message.push(message));
    });
    const toast = await toastController
      .create({
        message: error_message.join('<br>'),
        duration: 1000
      });
    toast.present();

    loading.hide();

  } else {

    loading.hide();

    const alert = await alertController
      .create({
        cssClass: 'my-custom-class',
        header: '工務店へ連絡完了',
        subHeader: '',
        //        message: '<div class="number">【受付番号 ' + data.reception_number + '】</div><div class="message">業社到着までしばらくお待ちください。</div>',
        message: '<div>【受付番号 ' + data.reception_number + '】</div><div style="padding-top:5px;">平日 10:00〜18:00 に対応します。</div>',
        buttons: ['トップへ戻る'],
      });


    await alert.present();  // Alertを表示する   
    await alert.onDidDismiss(); // "OK"が押されるまで待つ
    isConfirmModalOpen.value = false; // モーダルを閉じてトップページへ戻る

    router.replace("/dashboard"); // ダッシュボードへ一気に戻る

  }

  //await delay(1);

}




</script>

<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/rescue-request"></ion-back-button>
        </ion-buttons>
        <ion-title>レスキュー要請</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <h3 class="ion-padding-top" style="text-align: center;">{{ rescue.work_type.label }}トラブル</h3>

        <div class="ion-padding" style="font-size: 1.2em;">
          工務店：{{ user.builder_company_name }}<br>
          電話番号：<a :href="'tel:'+user.builder_phone_number">{{ user.builder_phone_number }}</a>
        </div>

        <ion-item lines="full">
          <ion-label position="fixed" mode="ios">
            発生場所
            <ion-text v-if="false" class="error ion-padding" color="danger">
              <strong>入力必須</strong>
            </ion-text>
          </ion-label>
          <ion-select
            @ionChange="rescue.place = $event.target.value"
            name="role"
            interface="alert"
            okText="OK"
            cancelText="キャンセル"
            slot="end"
          >
            <ion-select-option
              v-for="(place, index) in options.Places"
              :key="index"
              :value="place"
            >{{ place.label }}</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="fixed" mode="ios">状況</ion-label>
          <ion-select
            @ionChange="rescue.situation = $event.target.value"
            name="role"
            interface="alert"
            okText="OK"
            cancelText="キャンセル"
            slot="end"
          >
            <ion-select-option
              v-for="(situation, index) in options.Situations"
              :key="index"
              :value="situation"
            >{{ situation.label }}</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item lines="full">
          <ion-label position="stacked" mode="ios">詳しい状況</ion-label>
          <ion-textarea rows="4" cols="20" placeholder="タップして入力" v-model="rescue.message"></ion-textarea>
        </ion-item>

        <ion-text v-if="false" class="error ion-padding" color="danger">
          <strong>入力必須</strong>
        </ion-text>

        <file-upload v-model:url="rescue.field_photos[0]" title="現場写真 1" />
        <file-upload v-model:url="rescue.field_photos[1]" title="現場写真 2" />
        <file-upload v-model:url="rescue.field_photos[2]" title="現場写真 3" />

        <div class="ion-padding ion-text-center">
          <ion-button @click="isConfirmModalOpen = true">次へ</ion-button>
        </div>

        <ion-modal
          :is-open="isConfirmModalOpen"
          :swipe-to-close="true"
          :presenting-element="$parent.$refs.ionRouterOutlet"
        >
          <ion-page>
            <ion-header :translucent="true">
              <ion-toolbar>
                <ion-title>入力内容の確認</ion-title>
              </ion-toolbar>
            </ion-header>

            <ion-content :fullscreen="true">
              <div class="ion-padding-top ion-text-center">以下の内容でよろしければ、"申し込む" を押してください。</div>
              <h3
                class="ion-padding-top"
                style="text-align: center;"
              >{{ rescue.work_type.label }}トラブル</h3>
              <div class>
                <ion-item lines="full">
                  <ion-label position="stacked" mode="ios">発生場所</ion-label>
                  {{ rescue.place.label }}
                </ion-item>
              </div>
              <div>
                <ion-item lines="full">
                  <ion-label position="stacked" mode="ios">状況</ion-label>
                  {{ rescue.situation.label }}
                </ion-item>
              </div>
              <ion-item lines="full">
                <ion-label position="stacked" mode="ios">詳しい状況</ion-label>
                <div v-html="rescue.message.replace(/\n/g, '<br>')" />
              </ion-item>

              <ion-item lines="full">
                <ion-label position="stacked" mode="ios">現場写真（タップで拡大します）</ion-label>

                <n-image-group>
                  <n-space style="padding-top: 10px;">
                    <a-image
                      v-for="(photo, index) in rescue.field_photos"
                      :key="index"
                      :width="120"
                      :src="photo"
                    />
                  </n-space>
                </n-image-group>
              </ion-item>

              <div class="ion-padding ion-text-center">
                <ion-button @click="isConfirmModalOpen = false" color="light">戻る</ion-button>&nbsp;&nbsp;
                <ion-button @click="applicationRequest()">申し込む</ion-button>
              </div>
            </ion-content>
          </ion-page>
        </ion-modal>

        <ion-row>
          <ion-col></ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style>
.my-custom-class .alert-message .number {
  font-size: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
}
.my-custom-class .alert-message .message {
  font-size: 1.2em;
}

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-card {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
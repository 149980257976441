<script setup>

import { IonCol, loadingController, useIonRouter, IonRouterOutlet, IonLabel, IonDatetime, IonModal, IonMenuButton, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { ref } from "vue";
import dataService from '../../dataService.js';
import { useLoading } from '../../components/molecule/useLoading.js'
import { useRouter, useRoute } from "vue-router";
import { loading } from '../../components/molecule/loading.js'
import { VehicleCar24Filled, VehicleCar24Regular, HomePerson20Regular } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { format, parseISO } from 'date-fns';
const router = useRouter();
const route = useRoute();

const isConfirmModalOpen = ref(false);

const { id } = route.params;
const departure_plan_time = ref(format(new Date(), 'HH:mm'));

const rescue = ref(null);
const {
  displayError,
  productList,
  removeProduct,
  user,
  getRescue,
  logout,
  formatDT,
  departureRescue
} = dataService();

onIonViewWillEnter(async () => {
  const { error, data } = await getRescue(id);
  rescue.value = data;
});

onIonViewDidEnter(() => {
  console.log('onIonViewDidEnter!');
});

const close = async () => {
  const { error, data } = await departureRescue({
    'id': id,
    'departure_plan_time': format(new Date(), `yyyy-MM-dd ${departure_plan_time.value}:00`)
  });


  isConfirmModalOpen.value = false;


  router.back();


};

const kkk = ref(null)

const showModal = ref(false);

</script>

<template>
  <ion-page>
    <IconConfigProvider color="green" size="34">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button defaultHref="/vendor/home"></ion-back-button>
          </ion-buttons>
          <ion-title>レスキュー要請</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :fullscreen="true" v-if="rescue !== null">
        <div class="ion-padding">
          <n-space vertical>
            <n-card size="small" embedded :bordered="true">
              <template #header>
                 <h4
                  style="vertical-align: middle; display: inline-block; font-weight: bold;"
                >&nbsp;{{ rescue.work_type.label }}</h4>
              </template>
              要請日時：{{ formatDT(rescue.reception_time, 'M/dd H:mm') }}
              <br />
              住所：{{ rescue.rescue_title }}
              <br />
              場所：{{ rescue.place.label }}
              <br />
              状況：{{ rescue.situation.label }}
              <br />
              連絡事項：{{ rescue.message }}
              <n-image-group>
                <n-space style="padding-top: 10px;">
                  <a-image
                    v-for="(photo, index) in rescue.field_photos"
                    :key="index"
                    :width="100"
                    :src="photo"
                  />
                </n-space>
              </n-image-group>
            </n-card>
          </n-space>

          <div class="ion-padding">
            <ion-button @click="isConfirmModalOpen = true" expand="block">
              <Icon color="white" size="30">
                <VehicleCar24Filled />
              </Icon>&nbsp;出動する
            </ion-button>
          </div>
        </div>

        <ion-modal
          :is-open="isConfirmModalOpen"
          :swipe-to-close="true"
          :presenting-element="$parent.$refs.ionRouterOutlet"
        >
          <ion-page>
            <ion-header :translucent="true">
              <ion-toolbar>
                <ion-title>出動申請します</ion-title>
              </ion-toolbar>
            </ion-header>

            <ion-content :fullscreen="true">
              <ion-grid>
                <ion-row>
                  <ion-col>
                    <div class="ion-padding ion-text-center">
                      <h3>出発予定時間</h3>
                      <ion-datetime presentation="time" v-model="departure_plan_time"></ion-datetime>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>

              <div class="ion-text-center">
                <ion-button @click="isConfirmModalOpen = false" color="light">戻る</ion-button>&nbsp;&nbsp;
                <ion-button @click="close()">確定</ion-button>
              </div>
            </ion-content>
          </ion-page>
        </ion-modal>
      </ion-content>
    </IconConfigProvider>
  </ion-page>
</template>



<style scoped>
.n-step {
  text-align: left;
}

#n-steps-area {
  padding-left: 1em;
}
</style>
// 全体で使用する処理を Composition Function として定義する

// 秒数指定待ち
export const delay = (second) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve("done");
      } catch (e) {
        console.log("delay error!");
        reject(e);
      }
    }, second * 1000);
  });
};


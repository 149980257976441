<script setup>

import { IonCol, loadingController, IonSelect, toastController, IonSelectOption, useIonRouter, IonTextarea, IonRouterOutlet, IonLabel, IonDatetime, IonModal, IonMenuButton, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { computed, ref } from "vue";
import dataService from '../../dataService.js';
import dataServiceInProgress from '../../data-services/in-progress.js'
import { useLoading } from '../../components/molecule/useLoading.js'
import { useRouter } from "vue-router";
import { loading } from '../../components/molecule/loading.js'
import { VehicleCar24Filled, Check20Regular, HomePerson20Regular } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { format, parseISO } from 'date-fns';
import { logOutOutline, camera, documentTextOutline } from "ionicons/icons";
import FileUpload from "../../components/FileUpload.vue"

const router = useRouter();

const customAlertOptions = ref({
  header: '状況の更新',
  translucent: true
});

const {
  formatDT,
  sendReport
} = dataService();

const {
  shared_form,
  shared_rescue: rescue,
} = dataServiceInProgress();

const form = shared_form.value;
form.report_memo = "";
form.report_photos = ["", "", ""];
form.working_finish_time = format(new Date(), 'HH:mm');

const _sendReport = async () => {

  await loading.show();
  const { error, data } = await sendReport({
    status: form.progress_status,
    arrival_plan_time: format(parseISO(form.arrival_plan_time), 'HH:mm'),
    working_memo: form.working_memo,
    report_memo: form.report_memo,
    report_photos: form.report_photos.filter((value) => value !== ''),
    working_finish_time: form.working_finish_time
  }, rescue.value.id);

  loading.hide();

  if (data !== null) {
    rescue.value = data;
    router.replace("/vendor/home");
  }

  if (error !== null) {
    let error_message = [];
    Object.entries(error.data.errors).forEach(([key, item]) => {
      item.forEach((message) => error_message.push(message));
    });
    const toast = await toastController
      .create({
        message: error_message.join('<br>'),
        duration: 800
      });
    toast.present();
  }


};




</script>

<template>
  <ion-page>
    <IconConfigProvider color="green" size="34">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button defaultHref="/vendor/home"></ion-back-button>
          </ion-buttons>
          <ion-title>レスキュー出動中</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :fullscreen="true">
        <div class="ion-padding">
          <ion-item lines="full" id="open-working-finish-time">
            <ion-label>作業終了時刻</ion-label>
            <span
              style="font-size: 1.2em;"
            >{{ form.working_finish_time !== null ? form.working_finish_time : '未入力' }}</span>
            <ion-modal class="datetime-modal" trigger="open-working-finish-time">
              <ion-content>
                <ion-datetime
                  v-model="form.working_finish_time"
                  cancel-text="キャンセル"
                  doneText="決定"
                  presentation="time"
                  :show-default-buttons="true"
                ></ion-datetime>
              </ion-content>
            </ion-modal>
          </ion-item>

          <ion-item lines="full">
            <ion-label position="stacked" mode="ios">報告内容</ion-label>
            <ion-textarea rows="4" cols="20" v-model="form.report_memo" placeholder></ion-textarea>
          </ion-item>

          <file-upload v-model:url="form.report_photos[0]" title="報告用写真 1" />
          <file-upload v-model:url="form.report_photos[1]" title="報告用写真 2" />
          <file-upload v-model:url="form.report_photos[2]" title="報告用写真 3" />

          <div class="ion-padding ion-text-center">
            <ion-button @click="router.back();" color="light">戻る</ion-button>&nbsp;&nbsp;
            <ion-button @click="_sendReport()">報告を送信する</ion-button>
          </div>
        </div>
      </ion-content>
    </IconConfigProvider>
  </ion-page>
</template>



<style scoped>
.datetime-modal ion-datetime,
.datetime-popover ion-datetime {
  --background: var(--ion-color-step-50, #fff);
}
.datetime-popover {
  --width: 350px;
}
.datetime-modal {
  --border-radius: 8px;
  --width: 325px;
  --height: 254px;
}
</style>
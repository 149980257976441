import { createRouter, createWebHistory } from '@ionic/vue-router';
import DashBoard from '../views/DashBoard.vue';
import UserLogin from '../views/UserLogin.vue';
import UserCreate from '../views/UserCreate.vue';
import RescueRequest from '../views/RescueRequest.vue';
import InputTrouble from '../views/InputTrouble.vue';
import InputOtherTrouble from '../views/InputOtherTrouble.vue';

import VendorLogin from '../views/vendor/Login.vue';
import VendorHome from '../views/vendor/Home.vue';
import VendorDetail from '../views/vendor/Detail.vue';
import VendorInProgress from '../views/vendor/InProgress.vue';
import VendorWorkingComplete from '../views/vendor/WorkingComplete.vue';
import VendorReport from '../views/vendor/Report.vue';

import Experiment from '../views/Experiment.vue';
import Experiment2 from '../views/Experiment2.vue';

import dataService from '../dataService.js';

const routes = [

{
  path: '/:pathMatch(.*)', 
  component: VendorLogin 

},

  // 業社側
  {
    path: "/vendor/login",
    component: VendorLogin,
    meta: { requiresVendorAuth: false }
  },
  {
    path: "/vendor/home",
    component: VendorHome,
    meta: { requiresVendorAuth: true }
  },
  {
    path: "/vendor/detail/:id",
    component: VendorDetail,
    meta: { requiresVendorAuth: true }
  },
  {
    path: "/vendor/in-progress/:id",
    component: VendorInProgress,
    meta: { requiresVendorAuth: true }
  },

  {
    path: "/vendor/report",
    component: VendorReport,
    meta: { requiresVendorAuth: true }
  },

  {
    path: "/vendor/working-complete",
    component: VendorWorkingComplete,
    meta: { requiresVendorAuth: true }
  },

  // ユーザ側
  {
    path: '/',
    redirect: '/rescue-request'
  },
  {
    //    path: '/dashboard/:s?',
    path: '/dashboard',
    name: 'dashboard',
    component: DashBoard,
    meta: { requiresOwnerAuth: true },
  },
  {
    path: '/rescue-request',
    name: 'RescueRequest',
    component: RescueRequest,
    meta: { requiresOwnerAuth: true },
/*    beforeEnter: (to, from, next) => {
      console.log('asdfdsadsa');
      next();
    }*/
  },
  {
    path: '/input-trouble',
    name: 'InputTrouble',
    component: InputTrouble,
    meta: { requiresOwnerAuth: true },
  },
  {
    path: '/input-other-trouble',
    name: 'InputOtherTrouble',
    component: InputOtherTrouble,
    meta: { requiresOwnerAuth: true },
  },

  // これはアプリケーションの認証ルートを定義するものです
  {
    path: "/login",
    component: UserLogin,
    meta: { requiresOwnerAuth: false }
  },
  {
    path: "/create-account",
    component: UserCreate,
    meta: { requiresOwnerAuth: false },
  },
  /*
  {
    path: "/change-password",
    component: ChangePassword,
    meta: { requiresOwnerAuth: false },
  },
  */

  // 実験用ページ
  {
    path: '/e',
    name: 'experiment',
    component: Experiment,
    meta: { requiresOwnerAuth: false },
  },
  {
    path: '/e2',
    name: 'experiment2',
    component: Experiment2,
    meta: { requiresOwnerAuth: false },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {

  //console.log("call global router.beforeEach");

  //  console.log("beforeEach to: ", to.fullPath);
  //  console.log("beforeEach from: ", from.fullPath);
  //  console.log("beforeEach to meta:", to.meta)

  const { hasUser } = dataService();
  //  console.log("beforeEach", hasUser());

  //debugger;


  //  hasRescueInProgress();

  if (to.fullPath.indexOf("type=recovery") != -1) {
    next("/change-password");
  } else if (to.meta.requiresOwnerAuth && !hasUser()) {
    next("/login");
  } else if (to.meta.requiresVendorAuth && !hasUser()) {
    next("/vendor/login");
  } else {
    next();
  }
});

export default router

<script setup>

import { IonCol, loadingController, IonSelect, toastController, IonSelectOption, useIonRouter, IonTextarea, IonRouterOutlet, IonLabel, IonDatetime, IonModal, IonMenuButton, IonButtons, IonItem, IonList, IonIcon, menuController, IonMenu, IonBackButton, IonGrid, IonRow, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewWillEnter, onIonViewDidEnter, alertController } from '@ionic/vue';
import { computed, ref } from "vue";
import dataService from '../../dataService.js';
import { useLoading } from '../../components/molecule/useLoading.js'
import dataServiceInProgress from '../../data-services/in-progress.js'
import { useRouter, useRoute } from "vue-router";
import { loading } from '../../components/molecule/loading.js'
import { VehicleCar24Filled, Check20Regular, HomePerson20Regular } from '@vicons/fluent'
import { IconConfigProvider, Icon } from '@vicons/utils'
import { format, parseISO } from 'date-fns';
import { logOutOutline, camera, documentTextOutline } from "ionicons/icons";
const router = useRouter();
const route = useRoute();

const { id } = route.params;

const customAlertOptions = ref({
  header: '状況の更新',
  translucent: true
});

const {
  getRescue,
  formatDT,
  updateRescueStatus,
} = dataService();

const {
  shared_form,
  shared_rescue: rescue,
} = dataServiceInProgress();

const form = shared_form.value;

const fetch = async () => {
  const { error, data } = await getRescue(id);
  rescue.value = data;
  form.working_memo = rescue.value.working_memo;
  form.arrival_plan_time = formatDT(rescue.value.arrival_plan_time, "yyyy-MM-dd'T'HH:mm:ssXXX", null);
  if (rescue.value.working_status.value === 'departure') {
    form.progress_status = 'moving';
  } else {
    form.progress_status = 'working';
  }
};

const _updateRescueStatus = async () => {

  if (form.progress_status === 'complete' || form.progress_status === 'incomplete' || form.progress_status === 'cancel') {
    router.push('/vendor/report');
    return;
  }

  await loading.show();
  const { error, data } = await updateRescueStatus({
    status: form.progress_status,
    arrival_plan_time: format(parseISO(form.arrival_plan_time), 'HH:mm'),
    working_memo: form.working_memo
  }, id);

  if (data !== null) {
    rescue.value = data;
  }
  if (error !== null) {
    let error_message = [];
    Object.entries(error.data.errors).forEach(([key, item]) => {
      item.forEach((message) => error_message.push(message));
    });
    const toast = await toastController
      .create({
        message: error_message.join('<br>'),
        duration: 800
      });
    toast.present();
  }
  loading.hide();

};


fetch();

</script>

<template>
  <ion-page>
    <IconConfigProvider color="green" size="34">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-buttons slot="start">
            <ion-back-button defaultHref="/vendor/home"></ion-back-button>
          </ion-buttons>
          <ion-title>レスキュー出動中</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content :fullscreen="true" v-if="rescue !== null">
        <div class="ion-padding">
          <h3
            v-if="!rescue.arrival_plan_time"
            style="color:red; font-weight: bold; text-align: center;"
          >到着予定時刻を入力してください</h3>

          <n-space vertical>
            <n-card size="small" embedded :bordered="false" style="background-color: #ffe4e1;">
              <template #header>
                <h4
                  style="vertical-align: middle; display: inline-block; font-weight: bold;"
                >&nbsp;水漏れ</h4>
              </template>
              要請日時：{{ formatDT(rescue.reception_time, 'M/dd H:mm') }}
              <br />
              住所：{{ rescue.rescue_title }}
              <br />
              場所：{{ rescue.place.label }}
              <br />
              状況：{{ rescue.situation.label }}
              <br />
              連絡事項：{{ rescue.message }}
              <n-image-group>
                <n-space style="padding-top: 10px;">
                  <a-image
                    v-for="(photo, index) in rescue.field_photos"
                    :key="index"
                    :width="100"
                    :src="photo"
                  />
                </n-space>
              </n-image-group>
            </n-card>
          </n-space>

          <ion-item lines="full" id="open-arrival-plan-time">
            <ion-label>到着予定時刻</ion-label>
            <span
              style="font-size: 1.0em;"
            >{{ form.arrival_plan_time !== null ? format(parseISO(form.arrival_plan_time), 'H:mm') : '未入力' }}</span>
            <ion-modal class="datetime-modal" trigger="open-arrival-plan-time">
              <ion-content>
                <h4 class="ion-padding ion-text-center">出発予定時間</h4>
                <ion-datetime
                  v-model="form.arrival_plan_time"
                  cancel-text="キャンセル"
                  doneText="決定"
                  presentation="time"
                  :show-default-buttons="true"
                ></ion-datetime>
              </ion-content>
            </ion-modal>
          </ion-item>

          <ion-item lines="full">
            <ion-label>ステータス</ion-label>
            <ion-select
              :interface-options="customAlertOptions"
              v-model="form.progress_status"
              interface="alert"
              okText="確定"
              cancelText="閉じる"
            >
              <ion-select-option value="moving">準備〜移動中</ion-select-option>
              <ion-select-option value="working">作業中</ion-select-option>
              <ion-select-option value="complete">作業完了</ion-select-option>
              <ion-select-option value="incomplete">未完了終了</ion-select-option>
              <ion-select-option value="cancel">作業キャンセル</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item lines="full">
            <ion-label position="stacked" mode="ios">作業メモ（依頼主に公開されません）</ion-label>
            <ion-textarea rows="4" cols="20" v-model="form.working_memo" placeholder></ion-textarea>
          </ion-item>

          <div class="ion-padding">
            <ion-button @click="_updateRescueStatus()" expand="block">更新</ion-button>
          </div>
        </div>
      </ion-content>
    </IconConfigProvider>
  </ion-page>
</template>



<style scoped>
.datetime-modal ion-datetime,
.datetime-popover ion-datetime {
  --background: var(--ion-color-step-50, #fff);
}
.datetime-popover {
  --width: 350px;
}
.datetime-modal {
  --border-radius: 8px;
  --width: 325px;
  --height: 312px;
}
</style>
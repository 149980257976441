<script setup>
import { useSlots, useAttrs, toRefs } from 'vue';
import { loadingController, IonLabel, IonThumbnail, IonItem, IonList, IonIcon, onIonViewWillEnter, onIonViewDidEnter } from '@ionic/vue';
import { ref } from "vue";
import dataService from '../dataService.js';
import { camera } from "ionicons/icons";
import { loading } from '../components/molecule/loading.js'
//import { nanoid } from 'nanoid'

//const nanoid = customAlphabet('123456789abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ', 20)
//model.id = nanoid()

const uploadButton = ref(null);
//const img = ref(null);

const { upload } = dataService();

const slots = useSlots();
const attrs = useAttrs();

const props = defineProps({
  title: String,
  label: String,
  url: String,
});

const { url } = toRefs(props);
let image_url = ref(props.url);

const emit = defineEmits(['update:url', 'update:label']);


onIonViewWillEnter(() => {
  console.log('onIonViewWillEnter!');
});

onIonViewDidEnter(() => {
  console.log('onIonViewDidEnter!');
});


const imageChange = async (event) => {
  event.preventDefault();
  const file = event.target.files[0];
  if (!file) {
    return;
  }


  await loading.show();
  const { fileUrl, error } = await upload(file);
  loading.hide();


  image_url.value = fileUrl;
  console.log("url", fileUrl);

  emit('update:url', fileUrl)
  emit('update:label', "OK")



};


</script>


<template>
  <ion-item button detail="false" @click="uploadButton.click()" lines="full">
    <ion-label position="fixed" mode="ios">{{ title }}</ion-label>
    <template v-if="image_url">
      <div slot="end">
        <a-image :width="120" :src="image_url" />
      </div>
    </template>
    <template v-else>
      <ion-icon :icon="camera" size="large" slot="end"></ion-icon>
    </template>
  </ion-item>
  <input ref="uploadButton" type="file" style="display:none" @change="imageChange($event)" />
</template>


<style scoped>
</style>
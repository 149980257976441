<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>ユーザ登録</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true" class="ion-padding">
            <div class="ion-text-center ion-padding">
                メールアドレスとパスワードをご入力のうえ
                <br />「ユーザ登録」ボタンを押してください。
            </div>

            <ion-item>
                <ion-label>メールアドレス</ion-label>
                <ion-input type="email" v-model="creds.email"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label>パスワード</ion-label>
                <ion-input type="password" v-model="creds.password"></ion-input>
            </ion-item>
            <div class="ion-padding">
                <ion-button @click="doCreateAccount" expand="block">ユーザ登録</ion-button>
                <ion-button @click="router.back()" expand="block" fill="clear">キャンセル</ion-button>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    toastController,
    alertController
} from "@ionic/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import dataService from "../dataService";
export default {
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonItem,
        IonLabel,
        IonInput,
        IonButton
    },
    setup() {
        const router = useRouter();
        const { createAccount } = dataService();
        const creds = ref({
            email: "",
            password: ""
        });
        const doCreateAccount = async () => {
            try {
                const { email, password } = creds.value;
                const { user, error } = await createAccount(email, password);

                if (error) throw error;

                if (user) {

                    const alert = await alertController
                        .create({
                            cssClass: 'my-custom-class',
                            header: 'ユーザ登録',
                            message: 'ユーザ登録が完了しました。',
                            buttons: ['OK'],
                        });
                    await alert.present();

                    const { role } = await alert.onWillDismiss();

                    router.back();
                }
            } catch (e) {
                console.log(e.message)
                let message = e.message;
                /*
                if (message.indexOf("internal-error") !== -1) {
                    message = "パスワードは必須です。";
                } else if (message.indexOf("provide your email") !== -1) {
                    message = "メールアドレスは必須です。";
                } else if (message.indexOf("least 6 characters") !== -1) {
                    message = "パスワードは6文字以上を指定してください。";
                } else if (message.indexOf("validate email address: invalid format") !== -1) {
                    message = "メールアドレスが正しい形式ではありません。";
                } else if (message.indexOf("Rate limit exceeded") !== -1) {
                    message = "試行回数の上限に達しました。<br>しばらくお待ちください。";
                }*/
                const toast = await toastController
                    .create({
                        message: message,
                        duration: 1000
                    })
                toast.present();

            }
        };
        return {
            doCreateAccount,
            router,
            creds
        };
    }
};
</script>

<style scoped>
</style>
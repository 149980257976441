<script setup>
import { onIonViewDidEnter, IonContent, IonPage, IonItem, IonLabel, IonInput, IonImg, IonButton, IonFooter, toastController } from "@ionic/vue";
import { computed, ref } from "vue";
import dataService from "../dataService";
import logo from "../../public/assets/login-title-operater.png";
import { useRouter } from "vue-router";

const router = useRouter();
const { loginOwner, forgotPassword, getRescueActive } = dataService();
const isLoginButtonDisabled = ref(true);

const creds = ref({
    email: "",
    password: ""
});

const doLogin = async () => {
    isLoginButtonDisabled.value = false;
    try {
        const { email, password } = creds.value;
        {
            const { error } = await loginOwner(email, password);
            if (error) throw error;
        } {
            const { error, data } = await getRescueActive();
            if (error) throw error;

            if (data !== null && data.working_status !== null && data.rescue_close_date === null) {
                router.replace("/dashboard");
            } else {
                router.replace("/rescue-request");
            }
        }

    } catch (e) {
        const toast = await toastController
            .create({
                message: "ログインに失敗しました。",
                duration: 1000
            })
        toast.present();
        toast.onWillDismiss();
        isLoginButtonDisabled.value = true;
    }

};

const doForgotPassword = async () => {
    try {
        const { email } = creds.value;
        const { user, error } = await forgotPassword(email);
        if (user) alert("Check Email to Reset Password");
        if (error) throw error;
    } catch (e) {
        alert(e.message);
    }
};


//const isLoginButtonDisabled = computed(() => creds.value.email !== '' && creds.value.password);

</script>


<template>
    <ion-page>
        <ion-content :fullscreen="true" class="ion-padding">
            <div class="ion-text-center">
                <div class="ion-padding">
                    <h2>コールセンター代替サービス</h2>
                    <h3>お施主さま用サイト</h3>
                    <ion-img
                        :src="logo"
                        style="width:60%; display: block; margin: 0 auto; padding-top: 15px;"
                    ></ion-img>
                </div>
            </div>

            <!--
            <ion-item>
                <ion-label>お客様番号</ion-label>
                <span style="color: gray;">0014738</span>
            </ion-item>
            <ion-item>
                <ion-label>ご生年月日</ion-label>
            </ion-item>
            -->

            <n-space vertical>
                <n-input
                    size="large"
                    round
                    v-model:value="creds.email"
                    type="text"
                    placeholder="お客様ID"
                />
                <n-input
                    size="large"
                    round
                    v-model:value="creds.password"
                    type="password"
                    show-password-on="mousedown"
                    placeholder="パスワード"
                />
            </n-space>

            <div class="ion-padding">
                <ion-button
                    @click="doLogin"
                    expand="block"
                    :disabled="!isLoginButtonDisabled"
                >レスキュー要請</ion-button>
                <!--
                <ion-button
                    @click="router.push('/create-account')"
                    expand="block"
                    fill="clear"
                >ユーザ登録</ion-button>
                -->
            </div>
        </ion-content>
        <!--
        <ion-footer class="ion-text-center">
            <ion-button @click="doForgotPassword" fill="clear">パスワードを忘れた方はこちら</ion-button>
        </ion-footer>
        -->
    </ion-page>
</template>



<style scoped>
</style>